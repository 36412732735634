/*
 * Description of what this file is for
 *
 * @package  de.bistum-passau.www
 * @author David Hellmann [david@fredmansky.at]
 * videoSection
 */

const videoSection = {
  cfg: {
    name: 'videoSection',
    selectors: {
      videoSection: '.o-videoSection',
      videos: '.o-videoSection__fullScreenWrapper iframe',
      videosDescription: '.o-videoSectionFullScreen__description',
      videoThumbnails: '.a-videoThumbnail',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $videoSection: undefined,
    },
    els: {
      $videos: undefined,
      $videosDescription: undefined,
      $videoThumbnails: undefined,
    },
    requiredUCServices: [
      'BJz7qNsdj-7', // YOUTUBE
      'HyEX5Nidi-m', // VIMEO
    ],
    activeIndex: 0,
    isMobile: false,
  },

  setElements() {
    this.cfg.els.$videoThumbnails = document.querySelectorAll(this.cfg.selectors.videoThumbnails);
    this.cfg.els.$videos = document.querySelectorAll(this.cfg.selectors.videos);
    this.cfg.els.$videosDescription = document.querySelectorAll(
      this.cfg.selectors.videosDescription,
    );
    this.cfg.el.$videoSection = document.querySelector(this.cfg.selectors.videoSection);
  },

  activateFirstTopic() {
    if (this.cfg.els.$videoThumbnails[4]) {
      this.cfg.els.$videoThumbnails[4].classList.add(this.cfg.classes.active);
    }
    if (this.cfg.els.$videos[4]) {
      this.cfg.els.$videos[4].classList.add(this.cfg.classes.active);
    }
    if (this.cfg.els.$videosDescription[4]) {
      this.cfg.els.$videosDescription[4].classList.add(this.cfg.classes.active);
    }
    this.cfg.activeIndex = 4;
  },

  removeActiveStateForIndex(index) {
    this.cfg.els.$videoThumbnails[index].classList.remove(this.cfg.classes.active);
    this.cfg.els.$videos[index].classList.remove(this.cfg.classes.active);
    this.cfg.els.$videosDescription[index].classList.remove(this.cfg.classes.active);

    // stop video on index change
    const resetSource = this.cfg.els.$videos[index].getAttribute('embed-src');
        this.cfg.els.$videos[index].src = resetSource; // eslint-disable-line
  },

  addActiveStateForIndex(index) {
    this.cfg.els.$videoThumbnails[index].classList.add(this.cfg.classes.active);
    this.cfg.els.$videos[index].classList.add(this.cfg.classes.active);
    this.cfg.els.$videosDescription[index].classList.add(this.cfg.classes.active);
  },

  switchToVideoIndex(index) {
    if (index !== this.cfg.activeIndex) {
      this.removeActiveStateForIndex(this.cfg.activeIndex);
      this.addActiveStateForIndex(index);
      this.cfg.activeIndex = index;
    }

    if (this.cfg.isMobile) {
      if (this.cfg.el.$videoSection) {
        const videoSectionBc = this.cfg.el.$videoSection.getBoundingClientRect();
        window.scrollTo({ top: window.scrollY + videoSectionBc.top - 15, behavior: 'smooth' });
      }
    }
  },

  addEventListeners() {
    this.cfg.els.$videoThumbnails.forEach((thumb, index) => {
      thumb.addEventListener('click', () => {
        this.switchToVideoIndex(index);
      });
    });
  },

  setConsentForSection() {
    const placeHolder = this.cfg.el.$videoSection.nextElementSibling;

    // hide placeholder
    if (placeHolder && placeHolder.classList.contains('o-placeholderAudioVideoMultiple')) {
      placeHolder.classList.add('hidden');
    }

    // show video section
    this.cfg.el.$videoSection.classList.remove('hidden');

    // set data-src for iframes
    const iframesToRender = document.querySelectorAll('.iframeVideoSection');
    if (iframesToRender && iframesToRender.length > 0) {
      for (let i = 0; i < iframesToRender.length; i++) {
        this.setConsentForIframe(iframesToRender[i]);
      }
    }
  },

  setConsentForIframe(iframe) {
    const source = iframe.getAttribute('embed-src');
    if (source) {
      iframe.setAttribute('data-src', source);
      iframe.classList.add('lazyload');
      iframe.classList.remove('iframeVideoSection');
    }
  },

  // function to be called in cookie box script after consent is set
  renderVideoSectionAfterCookieSubmit() {
    this.initVideoSection();
  },

  initVideoSection() {
    this.setElements();

    if (this.cfg.el.$videoSection) {
      const cookieServices = window.UC_UI.getServicesBaseInfo();
      const videoCookiesActivated = cookieServices.filter(
        (service) => this.cfg.requiredUCServices.includes(service.id) && service.consent.status,
      );
      const requiredServicesConfirmed =
        videoCookiesActivated.length === this.cfg.requiredUCServices.length;

      // CHECK FOR YOUTUBE & VIMEO COOKIES

      if (requiredServicesConfirmed) {
        this.setConsentForSection();
        this.activateFirstTopic();
        this.addEventListeners();

        if (window.matchMedia('(max-width: 799px)').matches) {
          this.cfg.isMobile = true;
        }
      }
    }
  },

  init() {
    window.addEventListener('UC_UI_INITIALIZED', () => {
      this.initVideoSection();
    });
  },
};

export default videoSection;
